import axios from 'axios'
import { auth } from '../google/auth/firebaseAuth'
import { redirect } from 'react-router-dom'
import { pathEnableSecondFactor, pathSignOut } from '../routes'
import { multiFactor } from 'firebase/auth'

class DeferredPromise {
  constructor () {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
}

export const STATUSTEXTS = {
  MFAREQUIRED: 'User needs mfa',
  REAUTHREQUIRED: 'Credentials too old'
}

class MobileApi {
  #auth = async () => 'Bearer ' + await auth.currentUser?.getIdToken()
  #currentUser = async () => {
    await auth.authStateReady()
    return auth.currentUser
  }

  #oldAuth = async () => 'Bearer ' + await window.localStorage.getItem('access')
  #refreshToken = async () => window.localStorage.getItem('refresh')
  #basePath

  constructor () {
    this.#basePath = process.env.PUBLIC_API_URL
  }

  // a function that retries a call, after retrieving new access token.
  async #tryCallAgain (callToTry, params) {
    try {
      let authorization
      if (await this.#currentUser()) {
        await auth.currentUser.getIdTokenResult(true)
        authorization = await auth.currentUser.getIdToken(true)
        params.config.headers.Authorization = 'Bearer ' + authorization
      } else {
        authorization = (await this.getAccessToken())
        window.localStorage.setItem('refresh', authorization.data.refresh)
        params.config.headers.Authorization = 'Bearer ' + authorization.data.access
      }
      const responseReTry = await callToTry(...Object.values(params))
      return responseReTry
    } catch (error) {
      // if refresh token did not work, log out user and log unauthorized error
      if (error.response.status === 401) {
        redirect(pathSignOut)
        // page needs to be reloaded so that user sees login screen
        window.location.reload()
      }
      console.error(error)
    }
  }

  #getPath (path) {
    return this.#basePath + path
  }

  async #get (path, params) {
    const config = {
      headers: {
        Authorization: await this.#currentUser() ? await this.#auth() : await this.#oldAuth(),
        'X-UI': 'Web'
      },
      ...params
    }
    try {
      const response = await axios.get(this.#getPath(path), config)
      return response
    } catch (error) {
      const response = error.response
      let responseData = response.data
      if (responseData instanceof Blob) {
        responseData = await responseData.text()
      }
      if (response.status === 401) {
        if (response.statusText === STATUSTEXTS.MFAREQUIRED) {
          const mfaUser = multiFactor(await this.#currentUser())
          if (mfaUser.enrolledFactors.length === 0) {
            return redirect(pathEnableSecondFactor, { state: { mfaHideSecondary: true } })
          } else {
            return redirect(pathSignOut)
          }
        } else if (responseData === STATUSTEXTS.REAUTHREQUIRED) {
          const deferredPromise = new DeferredPromise()
          // Open re-authentication dialog
          window.dispatchEvent(new window.CustomEvent('reauth', {
            detail: {
              deferredPromise
            }
          }))
          // Wait for reauthentication
          const success = await deferredPromise.promise
          if (success) {
            // TODO: is this needed?
            config.headers.Authorization = await this.#auth()
            // Try the call again
            return await this.#get(path, params)
          } else {
            throw new Error('userNeedsReauth')
          }
        } else {
          auth.signOut()
          return redirect(pathSignOut)
        }
      } else {
        console.log(error)
        return error
      }
    }
  }

  async #put (path, data, custConf = {}) {
    const config = {
      headers: {
        Authorization: await this.#currentUser() ? await this.#auth() : await this.#oldAuth(),
        'X-UI': 'Web'
      },
      ...custConf
    }

    if (custConf.noAuth) {
      delete config.headers.Authorization
    }

    try {
      return await axios.put(this.#getPath(path), data, config)
    } catch (e) {
      let responseData = e.response.data
      if (responseData instanceof Blob) {
        responseData = await responseData.text()
      }
      if (e.response.status === 401 && await this.#refreshToken()) {
        return await this.#tryCallAgain(axios.put, { url: this.#getPath(path), data, config })
      } else if (responseData === STATUSTEXTS.REAUTHREQUIRED) {
        const deferredPromise = new DeferredPromise()
        window.dispatchEvent(new window.CustomEvent('reauth', {
          detail: {
            deferredPromise
          }
        }))
        const success = await deferredPromise.promise
        if (success) {
          config.headers.Authorization = await this.#auth()
          return await this.#put(path, data, custConf)
        } else {
          throw new Error('userNeedsReauth')
        }
      } else {
        console.error(e)
        return e
      }
    }
  }

  async #delete (path, data) {
    const config = {
      headers: {
        Authorization: await this.#currentUser() ? await this.#auth() : await this.#oldAuth(),
        'X-UI': 'Web'
      }
    }
    try {
      return await axios.delete(this.#getPath(path), config)
    } catch (e) {
      if (e.response.status === 401 && await this.#refreshToken()) {
        return await this.#tryCallAgain(axios.delete, { url: this.#getPath(path), config })
      } else if (e.response.data === STATUSTEXTS.REAUTHREQUIRED) {
        const deferredPromise = new DeferredPromise()
        window.dispatchEvent(new window.CustomEvent('reauth', {
          detail: {
            deferredPromise
          }
        }))
        const success = await deferredPromise.promise
        if (success) {
          return await this.#delete(path, data)
        } else {
          throw new Error('userNeedsReauth')
        }
      } else {
        console.error(e)
        return e
      }
    }
  }

  async getUser (auth) {
    const config = {
      headers: {
        Authorization: 'Bearer ' + auth
      }
    }
    const response = await axios.get(this.#basePath + '/v1/User/UserInfo', config)
    return response
  }

  async getCreateShiftData () {
    return await this.#get('/v1/User/CreateShiftData')
  }

  async getOrderShiftData () {
    return await this.#get('/v1/User/CreateCustomerShiftData')
  }

  async getShiftData (shiftId) {
    return await this.#get(`/v1/Shift/Employee/Shift?shiftId=${shiftId}&ui=1`)
  }

  async getCalendarShiftData (shiftId) {
    return await this.#get(`/v1/Shift/Calendar/Shift?shiftId=${shiftId}&ui=1`)
  }

  async getEditShiftData (shiftId) {
    return await this.#get(`/v1/Shift/Employee/Shift/Edit?${shiftId}`)
  }

  async updateShift (shiftData) {
    return await this.#put('/v1/Shift/Employee/Shift/Update', shiftData)
  }

  async cancelShift (shiftId) {
    return await this.#put(`/v1/Shift/Employee/Shift/Cancel?shiftId=${shiftId}`)
  }

  async saveWorkShifts (shiftData) {
    return await this.#put('/v1/Shift/Employee/Shift/AddMultiple', shiftData)
  }

  async getDoneWorkShifts (query) {
    return await this.#get(`/v1/Shift/Employee/DoneShiftEntriesSortable?${query}`)
  }

  async getPlacesOfWorkForShifts () {
    return await this.#get('/v1/Shift/Employee/DoneShiftEntries/PlacesOfWork')
  }

  async getAvailableEmployeesForShifts (params) {
    return await this.#get(`/v1/Shift/Customer/Shift/EmployeesToSuggest?${params}`)
  }

  async createShift (data) {
    return await this.#put('/v1/Shift/Customer/Create', data)
  }

  async reportShift (data) {
    return await this.#put('/v1/Shift/Customer/CreateEntries', data)
  }

  async getUpcomingShifts (query) {
    return await this.#get(`/v1/Shift/Employee/UpcomingShifts?${query}`)
  }

  async setLanguage (lang) {
    return await this.#put('/v1/User/Language', { Culture: lang })
  }

  async getUpdateNotices () {
    return await this.#get('/v1/User/UpdateNotices')
  }

  async setNoticeAsRead (id) {
    await this.#put(`/v1/User/UpdateNotices/${id}/MarkAsRead`)
  }

  async getNoticeLink (id) {
    const config = {
      headers: {
        Authorization: await this.#auth()
      },
      responseType: 'blob'
    }
    try {
      return await axios.get(this.#getPath(`/v1/User/UpdateNotices/${id}`), config)
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async getLatestNoticeLink () {
    const config = {
      headers: {
        Authorization: await this.#auth()
      },
      responseType: 'blob'
    }
    try {
      const response = await axios.get(this.#getPath('/v1/User/UpdateNotices/Latest'), config)
      return response
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async getStaffNews () {
    return await this.#get('/v1/User/Staffnews')
  }

  signEmploymentContract = (data) => this.#put('/v1/Employee/SignContract', data)

  async getEmploymentContracts () {
    return await this.#get('/v1/Employee/EmploymentContracts')
  }

  async getEmploymentContractPdf (index) {
    try {
      return await this.#get(`/v1/Employee/EmploymentContractPDF?index=${index}`, { responseType: 'blob' })
    } catch (e) {
      return e
    }
  }

  async getEmploymentContractPreview (contractId) {
    try {
      return await this.#get(`/v1/Employee/EmploymentContractPreview?contractId=${contractId}`)
    } catch (e) {
      return e
    }
  }

  async getEmploymentAttachmentPreview (attachmentId) {
    try {
      return await this.#get(`/v1/Employee/EmploymentContractAttachmentPreview?attachmentId=${attachmentId}`)
    } catch (e) {
      return e
    }
  }

  async getEmploymentContractPreviewPDF (contractId) {
    try {
      const config = {
        headers: {
          Authorization: await this.#auth()
        },
        responseType: 'blob'
      }
      return await this.#get(`/v1/Employee/EmploymentContractPreviewPDF?contractId=${contractId}`, config)
    } catch (e) {
      return e
    }
  }

  async getEmploymentAttachmentPreviewPDF (contractId) {
    try {
      const config = {
        headers: {
          Authorization: await this.#auth()
        },
        responseType: 'blob'
      }
      return await this.#get(`/v1/Employee/EmploymentContractAttachmentPreviewPDF?attachmentId=${contractId}`, config)
    } catch (e) {
      return e
    }
  }

  /**
   * @param {Object} data Optional parameter defining what message to fetch
   * @param {Number} data.skip How many entries will be skipped from the start of result (default is 0)
   * @param {Number} data.take How many entries will be included in the result (default is 10)
   * @param {Date} data.from Show only messages that is sent from or after given date and time
   * @param {Date} data.until Show only messages that take place before or until given date and time
   * @param {String} data.messageForm Show only certain type of messages (default is (PushNotification))
   * @param {Boolean} data.onlyUnopened Get only messages which are not marked opened (Push-notifications can be marked as opened)
   * @param {Number} data.messageType Show only messages of a certain type (shift suggestion or other)
   * @returns {Promise&Array<
   * {Id: Number,
   * Subject: String,
   * Message: String,
   * SendTime: Date,
   * MessageTypeId: Number,
   * Sender: String,
   * MessageFormId: Number,
   * SendTo: String,
   * ShiftId: Number,
   * IsOpened: Boolean,
   * MessageToken: String}>} Message log Promise -> Array
   */
  async getMessages (data = { skip: null, take: null, from: null, until: null, messageForm: null, onlyUnopened: null, messageType: null }) {
    let url = '/v1/Message/Employee/Messages?null'
    Object.keys(data).forEach(key => {
      url += `&${key}=${data[key]}`
    })
    return await this.#get(url)
  }

  async downloadPdf (path) {
    const config = {
      headers: {
        Authorization: await this.#auth()
      },
      responseType: 'blob'
    }
    return await axios.get(path, config)
  }

  async getUserInfo () {
    return await this.#get('/v1/User/UserInfo')
  }

  async getNextOfKin () {
    return await this.#get('/v1/User/NOK')
  }

  async getHolidayManagementLink (auth) {
    return await this.#get('/v1/Employee/GetHolidayManagementLink', undefined, auth || await this.#auth())
  }

  async getEmployeePaymentSchedules () {
    return await this.#get('/v1/Salary/Employee/SalaryPaymentSchedules')
  }

  /**
   *
   * @returns {Array<{
   * PaymentDate (string),
   * VersionNumber (integer),
   * DownloadToken (string)>} Returns an array of salary slip models with download tokens for employee
   */
  async getSalarySlips () {
    return await this.#get('/v1/Salary/Salaryslips')
  }

  /**
   * Fetches a single salary slip as a blob that can be made into a pdf
   * @param {Object} token What salary slip to fetch
   * @returns A single salary slip as blob data
   */
  async getSalarySlipsPdf (token) {
    const headers = {
      headers: {
        Client: 'myStaffMobileApp',
        DownloadKey: token,
        Authorization: await this.#auth()
      },
      responseType: 'blob'
    }
    return await this.#put('/v1/Salary/Salaryslips/slip', null, headers)
  }

  async getAnnualLeaveBalance () {
    return await this.#get('/v1/Employee/AnnualLeaveBalance')
  }

  async getPushNotificationSettings () {
    return await this.#get('/v1/PushNotification/Settings')
  }

  async getIdentityCard () {
    return await this.#get('/v1/Employee/IdentityCard')
  }

  async getIdentityCardImage () {
    return await this.#get('/v1/Employee/IdentitycardImage', { responseType: 'blob' })
  }

  async getNotificationOptions () {
    return await this.#get('/v1/PushNotification/NotificationOptions')
  }

  async setWorkSuggestion (data) {
    return await this.#put(`/v1/PushNotification/WorkSuggestions?preferredNotification=${data}`)
  }

  async savePersonalAddress (addr) {
    return await this.#put('/v1/User/Employee/Address', addr)
  }

  async saveBank (data) {
    return await this.#put('/v1/User/Employee/Bank', data)
  }

  async saveNextOfKin (data) {
    return await this.#put('/v1/User/Employee/NextOfKin', data)
  }

  async savePersonalInfo (data) {
    return await this.#put('/v1/User/Employee/PersonalInfo', data)
  }

  async changePassword (oldPassword, newPassword) {
    const passwordModel = {
      OldPassword: oldPassword,
      NewPassword: newPassword
    }
    return await this.#put('/v1/User/Password', passwordModel)
  }

  async changeEmail (data) {
    return await this.#put(`/v1/User/UpdateEmail?email=${data}`)
  }

  // async getFaqTexts (int) {
  //   return await this.#get('/v1/User/ContactText', int)
  // }

  async sendEmailFromUser (subject, message, sendTo) {
    const FAQMessageModel = {
      Subject: subject,
      Message: message,
      SendTo: sendTo
    }

    return await this.#put('/v1/Support/SendEmail', FAQMessageModel)
  }

  // UNTESTED. WAITING FOR DATABASE FUNCTIONALITY
  async activateForNotifications (auth, token) {
    this.#put('/v1/PushNotification/Activate', token)
  }

  async deactivateForNotifications (auth, token) {
    this.#put('/v1/PushNotification/Deactivate', token)
  }

  async getOpenVacancies (query) {
    return await this.#get(`/v1/Employee/SearchVacancies?${query}`)
  }

  async likeVacancy (vacancyId) {
    return await this.#put(`/v1/Employee/LikeVacancy?vacancyId=${vacancyId}&like=true`)
  }

  async dislikeVacancy (vacancyId) {
    return await this.#put(`/v1/Employee/LikeVacancy?vacancyId=${vacancyId}&like=false`)
  }

  async getVacancyJobFields () {
    return await this.#get('/v1/Employee/GetVacancyJobFields')
  }

  async getMaintenancePreInfoMessage () {
    return await axios.get(this.#getPath('/v1/System/MaintenancePreInfoMessage'))
  }

  async getCalendarEvents (year, month) {
    return await this.#get(`/v1/Calendar/${year}/${month}`)
  }

  async getPoolConnections () {
    return await this.#get('/v1/Pool/Employee/PoolConnections')
  }

  async linkNewstaffUID (uid, email) {
    return await this.#put(`/v1/User/AddNewstaffUID?uid=${uid}&email=${email}`, { uid, email }, { headers: { Authorization: await this.#oldAuth() } })
  }

  async acceptPoolInvitation (id) {
    return await this.#put(`/v1/Pool/Employee/Invitation/Accept/${id}`)
  }

  async rejectPoolInvitation (id) {
    return await this.#put(`/v1/Pool/Employee/Invitation/Reject/${id}`)
  }

  async removePool (id) {
    return await this.#delete(`/v1/Pool/Employee/PoolConnections/${id}`)
  }

  // call uses axios straight to prevent infinite loop
  async getAccessToken (auth) {
    const params =
    {
      fromApp: 'newStaff'
    }
    const config = {
      auth: await auth,
      params
    }
    return await axios.post(this.#getPath('/v1/User/AccessToken'), {}, config)
  }

  async updateEmailAddress (email) {
    return await this.#put(`/v1/User/UpdateEmail?email=${email}`, { email })
  }

  async saveCalendarNotAvailable (eventBundle) {
    return await this.#put('/v1/Calendar/Employee/Events/Add/NotAvailable', eventBundle)
  }

  async saveCalendarShiftRequest (eventBundle) {
    return await this.#put('/v1/Calendar/Employee/Events/Add/ShiftRequest', eventBundle)
  }

  async updateCalendarEvents (eventBundle, eventId) {
    return await this.#put(`/v1/Calendar/Employee/Events/Edit/${eventId}`, eventBundle)
  }

  async deleteCalendarNotAvailable (id) {
    return await this.#delete(`/v1/Calendar/Employee/Events/NotAvailable/${id}`)
  }

  async deleteCalendarShiftRequest (id) {
    return await this.#delete(`/v1/Calendar/Employee/Events/ShiftRequest/${id}`)
  }

  async acceptShiftTimeChanges (params) {
    return await this.#put(`/v1/Shift/Employee/Shift/AcceptShiftTimeChanges?${params}`)
  }

  async rejectShiftTimeChanges (params) {
    return await this.#put(`/v1/Shift/Employee/Shift/RejectShiftTimeChanges?${params}`)
  }

  async acceptSuggestedShift (id) {
    return await this.#put(`/v1/Shift/Employee/SuggestedShifts/Book/${id}`)
  }

  async rejectSuggestedShift (id) {
    return await this.#delete(`/v1/Shift/Employee/SuggestedShifts/${id}`)
  }

  async getOpenAndSuggestedShifts (params) {
    return await this.#get('/v1/Shift/Employee/SuggestedOrOpenShifts', params)
  }

  async rejectSuggestedBundle (id) {
    return await this.#delete(`/v1/Shift/Employee/SuggestedBundleShift/${id}`)
  }

  async acceptSuggestedBundle (id) {
    return await this.#put(`/v1/Shift/Employee/BundledShifts/Accept/${id}`)
  }

  async getTopAlerts () {
    return await this.#get('/v1/Employee/GetTopAlerts')
  }

  async getSharedFiles () {
    return await this.#get('/v1/Employee/SharedFiles')
  }

  async getSingleFile (file) {
    try {
      return await this.#get(`/v1/Employee/SharedFiles/${file}`, { responseType: 'blob' })
    } catch (e) {
      return e
    }
  }

  async setEmailDisabled (disable) {
    return await this.#put('/v1/Employee/SetDisableEmail', {}, { params: { disable } })
  }

  async getEmailSettings () {
    return await this.#get('/v1/Employee/EmailSettings')
  }

  async getWorkTimePeriods () {
    return await this.#get('/v1/Employee/WorkTimePeriods')
  }

  async sendVerificationLink (email) {
    return await this.#put(`/v1/User/SendLinkToEmail/${email}`, null, { noAuth: true })
  }
}

const mobileApi = new MobileApi()
export default mobileApi
